<template>
    <div id="tru-fw">
        <div class="tools flex flex-space-between">
            <div class="flex flex-left-center">
                <button class="btn">服务门店</button>
                <div class="tools-tip">添加合作门店小程序，给它私域用户提供形象穿搭服务</div>
            </div>
            <div>
                <!--                <button @click="activePicker" class="btn btn-white">添加机构</button>-->
            </div>
        </div>

        <div>
            <a-table :rowKey="record => record.app_id "
                     :data-source="table.data"
                     :pagination="table.pagination"
                     @change="tableChanged"
                     :locale="{emptyText:'暂无托管机构'}"
                     :loading="table.loading" class="at-table">

                <a-table-column title="#">
                    <template slot-scope="val,row,idx">
                        {{ ((query.page - 1) * table.pagination.pageSize) + (idx + 1) }}
                    </template>
                </a-table-column>
                <a-table-column title="机构头像" align="center" data-index="avatar">
                    <template slot-scope="val">
                        <img class="list-avatar" :src="val" alt="">
                    </template>
                </a-table-column>
                <a-table-column title="机构名称" align="center" data-index="nickname"></a-table-column>
                <a-table-column title="当前状态" align="center" data-index="status">
                    <template slot-scope="status">
                        <template v-if="status === 0">待合作</template>
                        <template v-if="status === 1">已合作</template>
                        <template v-if="status === 2">已拒绝</template>
                        <template v-if="status === 3">已解约</template>
                        <template v-if="status === 4">已取消</template>
                    </template>
                </a-table-column>
                <a-table-column title="操作">
                    <template slot-scope="item">
                        <template v-if="item.status === 1 ">
                            <button @click="cancel(item)" class="btn btn-white cancel-btn">解除合作</button>
                        </template>
                        <template v-else>
                            <span>-</span>
                        </template>
                    </template>
                </a-table-column>

            </a-table>
        </div>

    </div>
</template>

<script>
import api from "@/repo/api";

export default {
    name: "FuWu",
    data() {
        return {
            showPickerModal: false,
            pickerSearchText: "",
            searchAppData: undefined,
            query: {
                page: 1,
            },
            table: {
                data: null,
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 个托管小程序`;
                    },
                },
            }
        }
    },
    mounted() {
        this.getFirstList()
    },
    methods: {
        activePicker() {
            this.showPickerModal = true
            this.pickerSearchText = ""
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        },
        getList() {
            this.table.loading = true

            api.get('/trusteeship/children', this.query, (data) => {
                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

            })
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        confirmAddApp() {

            if (!this.searchAppData) return this.$message.error('请先搜索小程序')

            this.$loading.show()

            api.post('/trusteeship/apply', {app_id: this.searchAppData.app_id}, res => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) {
                    this.showPickerModal = false
                    this.getFirstList()
                }
            })
        },
        redo(item) {
            this.$confirm({
                title: "确定要再次发起合作？",
                onOk: () => {
                    this.searchAppData = item
                    this.confirmAddApp()
                }
            })
        },
        cancel(item) {
            this.$confirm({
                title: "确定要解除合作？",
                onOk: () => {

                    this.$loading.show()

                    api.post('/trusteeship/cancel-invite', {app_id: item.app_id}, res => {

                        this.$loading.hide()

                        this.$message.data(res)

                        if (res.code === 0) {
                            this.getFirstList()
                        }
                    })
                }
            })
        },
        searchApp() {

            if (!this.pickerSearchText) return;

            this.$loading.show()

            api.get('/trusteeship/search-app', {app_id: this.pickerSearchText}, (res) => {

                this.$loading.hide()

                this.searchAppData = res.data

            })
        }
    }
}
</script>

<style scoped lang="less">

.tools {
    margin-bottom: 24px;
}

.page-title {
    margin-bottom: 24px;
}

.tools-tip {
    height: 32px;
    background: #F5F5F5;
    font-size: 14px;
    color: #606266;
    text-align: left;
    line-height: 32px;
    width: 392px;
    padding-left: 14px;
    margin-left: 4px;
}

.pt1 {
    font-size: 14px;
    color: #333333;
    line-height: 16px;
}

.pt-input {
    margin-top: 16px;
    width: 100%;
}

.search-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #333333;
    background-image: url("/assets/icons/btn-search.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
}

.sh-container {
    margin-top: 20px;
}

.cancel-btn {
    border: 1px solid #F5606D;
    color: #F5606D;

    &:hover {
        background-color: transparent;
    }
}

.list-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #ddd;
}

.sh-img {
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border-radius: 50%;
}

.sh-name {
    font-size: 14px;
    color: #333333;
    line-height: 16px;
}
</style>