<template>
    <div>
        <div class="app-id-container flex flex-left-center">
            <img class="card-icon" src="/assets/icons/card-icon.png" alt="">
            <span>本小程序ID:</span>
            <span class="app-id">{{ app.authorizer_appid }}</span>
        </div>
        <div class="page-title">托管设置</div>

        <tuo style="margin-bottom: 56px" />

        <fu-wu style="margin-bottom: 56px" />

    </div>
</template>

<script>

import Tuo from "@/views/AST/Xcx/Com/Tuo.vue";
import FuWu from "@/views/AST/Xcx/Com/FuWu.vue";

export default {
    name: "Trusteeship",
    components: {FuWu, Tuo},
    props: {
        app: Object
    },
    data() {
        return {

        }
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<style scoped lang="less">
.app-id-container {
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;

    img {
        margin-right: 4px;
    }

    .app-id {
        margin-left: 24px;
        font-size: 14px;
        color: #333333;
        line-height: 16px;
    }
}

.card-icon {
    width: 20px;
    height: 20px;
}

.tools {
    margin-bottom: 24px;
}

.page-title {
    margin-bottom: 24px;
}

.tools-tip {
    height: 32px;
    background: #F5F5F5;
    font-size: 14px;
    color: #606266;
    text-align: left;
    line-height: 32px;
    width: 392px;
    padding-left: 14px;
    margin-left: 4px;
}

.pt1 {
    font-size: 14px;
    color: #333333;
    line-height: 16px;
}

.pt-input {
    margin-top: 16px;
    width: 100%;
}

.search-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #333333;
    background-image: url("/assets/icons/btn-search.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
}

.sh-container {
    margin-top: 20px;
}

.cancel-btn {
    border: 1px solid #F5606D;
    color: #F5606D;

    &:hover {
        background-color: transparent;
    }
}

.list-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #ddd;
}

.sh-img {
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border-radius: 50%;
}

.sh-name {
    font-size: 14px;
    color: #333333;
    line-height: 16px;
}
</style>